import React from "react";

function _calculateAge() {
    var ageDifMs = Date.now() - new Date(1994, 8, 1).getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}


const About = {
    age: _calculateAge(),
    location: "Maribor, Slovenia",
    job: (<a href="https://ii.feri.um.si/sl/person/patrik-rek/" target="_blank" rel="noopener noreferrer">Teaching assistant @ UM FERI</a>),
    email: (<a href="mailto:patrik@patrikrek.eu">patrik@patrikrek.eu</a>),
    photo: (<img src="/photos/RekPatrik.jpg" alt="Patrik Rek" className="profile-photo" />)
};

export default About;