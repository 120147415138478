/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState } from "react";
import Typewriter from 'typewriter-effect';

function Header(props) {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(/photos/header.jpg)",
          backgroundPosition: 'left top',
          backgroundSize: 'cover'
        }}
      >
        <Typewriter options={{
          strings: ["Portrait", "Event", "Nature", "Sport", "Animal", "Concert"],
          autoStart: true,
          loop: true
        }} />
        <span className="Typewriter"> photography</span>
        <div className="filter" />
        <div className="category category-absolute">
          <span className="scroll-text">Scroll down for more photos.</span>
          <a className="scroll-down" onClick={() => props.scrollDown()}>
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>

      </div>
    </>
  );
}

export default Header;
