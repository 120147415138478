import React from "react";
import Category from "data/Categories";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

function CategoriesMenu(props) {
    const categoryNames = [];
    categoryNames[Category.all] = "All photos";
    categoryNames[Category.portrait] = "Portraits";
    categoryNames[Category.event] = "Events";
    categoryNames[Category.nature] = "Nature";
    categoryNames[Category.sport] = "Sports";
    categoryNames[Category.animal] = "Animals";
    categoryNames[Category.concerts] = "Concerts";
    categoryNames[Category.other] = "Others";

    const [selectedCategory, setSelectedCategory] = React.useState(Category.all);
    const [selectedCategoryName, setSelectedCategoryName] = React.useState(categoryNames[Category.all]);


    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const toggleDD = () => setDropdownOpen(prevState => !prevState);

    const selectCategory = (category) => {
        setSelectedCategory(category);
        props.categorySelected(category);
        setSelectedCategoryName(categoryNames[category]);
    }


    return (
        <>
            <div className="row mt-2 ml-2">
                <Dropdown className="" color="info" isOpen={dropdownOpen} toggle={toggleDD}>
                    <DropdownToggle className="btn-round" caret>
                        {selectedCategoryName}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem active={selectedCategory === Category.all} onClick={() => selectCategory(Category.all)}>{categoryNames[Category.all]}</DropdownItem>
                        <DropdownItem active={selectedCategory === Category.portrait} onClick={() => selectCategory(Category.portrait)}>{categoryNames[Category.portrait]}</DropdownItem>
                        <DropdownItem active={selectedCategory === Category.event} onClick={() => selectCategory(Category.event)}>{categoryNames[Category.event]}</DropdownItem>
                        <DropdownItem active={selectedCategory === Category.nature} onClick={() => selectCategory(Category.nature)}>{categoryNames[Category.nature]}</DropdownItem>
                        <DropdownItem active={selectedCategory === Category.sport} onClick={() => selectCategory(Category.sport)}>{categoryNames[Category.sport]}</DropdownItem>
                        <DropdownItem active={selectedCategory === Category.animal} onClick={() => selectCategory(Category.animal)}>{categoryNames[Category.animal]}</DropdownItem>
                        <DropdownItem active={selectedCategory === Category.concerts} onClick={() => selectCategory(Category.concerts)}>{categoryNames[Category.concerts]}</DropdownItem>
                        <DropdownItem active={selectedCategory === Category.other} onClick={() => selectCategory(Category.other)}>{categoryNames[Category.other]}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>

                {/* <Button
                    className="btn-round"
                    color="info"
                    disabled={selectedCategory === Category.all}
                    outline={selectedCategory !== Category.all}
                    type="button"
                    onClick={() => selectCategory(Category.all)}
                >
                    All photos
                </Button>
                <Button
                    className="btn-round"
                    color="info"
                    disabled={selectedCategory === Category.portrait}
                    outline={selectedCategory !== Category.portrait}
                    type="button"
                    onClick={(e) => selectCategory(Category.portrait)}
                >
                    Portraits
                </Button>
                <Button
                    className="btn-round"
                    color="info"
                    disabled={selectedCategory === Category.event}
                    outline={selectedCategory !== Category.event}
                    type="button"
                    onClick={() => selectCategory(Category.event)}

                >
                    Events
                </Button>
                <Button
                    className="btn-round"
                    color="info"
                    disabled={selectedCategory === Category.nature}
                    outline={selectedCategory !== Category.nature}
                    type="button"
                    onClick={() => selectCategory(Category.nature)}

                >
                    Nature
                </Button>
                <Button
                    className="btn-round"
                    color="info"
                    disabled={selectedCategory === Category.sport}
                    outline={selectedCategory !== Category.sport}
                    type="button"
                    onClick={() => selectCategory(Category.sport)}

                >
                    Sports
                </Button>
                <Button
                    className="btn-round"
                    color="info"
                    disabled={selectedCategory === Category.animal}
                    outline={selectedCategory !== Category.animal}
                    type="button"
                    onClick={() => selectCategory(Category.animal)}

                >
                    Animals
                </Button>
                <Button
                    className="btn-round"
                    color="info"
                    disabled={selectedCategory === Category.concerts}
                    outline={selectedCategory !== Category.concerts}
                    type="button"
                    onClick={() => selectCategory(Category.concerts)}

                >
                    Concerts
                </Button>
                <Button
                    className="btn-round"
                    color="info"
                    disabled={selectedCategory === Category.other}
                    outline={selectedCategory !== Category.other}
                    type="button"
                    onClick={() => selectCategory(Category.other)}

                >
                    Others
                </Button> */}
            </div>
        </>
    );
}

export default CategoriesMenu;