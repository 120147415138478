const Category = {
    "all": 0,
    "portrait": 1,
    "event": 2,
    "nature": 3,
    "sport": 4,
    "animal": 5,
    "concerts": 6,
    "other": 7
}

export default Category;