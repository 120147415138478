/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import Navbar from "components/Navbar.js";
import Header from "components/Header.js";
import Footer from "components/Footer.js";
import CategoriesMenu from "components/CategoriesMenu.js";
import AboutModal from "components/AboutModal.js";
import * as Scroll from 'react-scroll';
import PatrikGallery from 'components/Gallery';
import images from 'data/Photos';
import Category from 'data/Categories';
import { Helmet } from 'react-helmet';

function Index(props) {
  const [modal, setModal] = React.useState(false);
  const [selectedPhotos, setSelectedPhotos] = React.useState(images);
  const toggleModal = () => {
    setModal(!modal);
  };
  document.documentElement.classList.remove("nav-open");
  // Or Access Link,Element,etc as follows
  let Element = Scroll.Element;
  let scroller = Scroll.scroller;

  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });

  const scrollDown = () => {
    scroller.scrollTo("gallery", {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -106,
    })
  }

  const selectCategory = (category) => {
    if (category === Category.all) {
      setSelectedPhotos(images);
    }
    else {
      let sel = images.filter((x) => x.categories.includes(category));
      setSelectedPhotos(sel);
    }
  }

  return (
    <>
      <Helmet>
        <title>Patrik Rek Photography</title>
        <meta charset="utf-8" />
        <meta name="author" content="Patrik Rek" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="Hobby photographer from Maribor, Slovenia, specialized in portrait and nature photography." />
        <meta property="og:title" content="Patrik Rek Photography" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://patrikrek.eu" />
        <meta property="og:image" content="https://patrikrek.eu/image.jpg" /> {/* PRIPRAVI!!! */}
        <meta name="robots" content="index, follow" />
        <link href="https://patrikrek.eu" rel="canonical" />
      </Helmet>
      <Navbar toggleModal={toggleModal} />
      <Header scrollDown={() => scrollDown()} />
      <Element name="gallery" className="main px-3 pt-2" >
        <CategoriesMenu categorySelected={selectCategory} />
        <PatrikGallery images={selectedPhotos} />
      </Element>
      <Footer />
      <AboutModal modal={modal} toggleModal={toggleModal} />
    </>
  );
}

export default Index;
