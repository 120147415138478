import React from "react";
import { Modal } from "reactstrap";
import About from "data/About";

function AboutModal(props) {

    return (
        <Modal isOpen={props.modal} toggle={props.toggleModal}>
            <div className="modal-header">
                <button
                    aria-label="Close"
                    className="close"
                    type="button"
                    onClick={props.toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
                <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                >
                    About me
                  </h5>
            </div>
            <div className="modal-body">
                Portrait, event and nature hobby photographer.<hr />
                <div className="row">
                    <div className="col-4">
                        {About.photo}
                    </div>
                    <div className="col-8">
                        <p><strong>Age: </strong>{About.age}</p>
                        <p><strong>Location: </strong>{About.location}</p>
                        <p><strong>Job: </strong>{About.job}</p>
                        <p><strong>E-mail: </strong>{About.email}</p>
                    </div>
                </div>

            </div>

        </Modal>
    );
}

export default AboutModal;
