import React, { useState } from 'react';
import {
    CardColumns,
    Card,
    CardImg
} from 'reactstrap';

import Lightbox from 'react-image-lightbox';


function PatrikGallery(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    let images = [];
    if (typeof props.images !== "undefined")
        images = props.images;
    let objects = [];
    for (const [index, img] of images.entries()) {
        let object = (<Card key={`card-${index}`}>
            <CardImg src={img.thumbnail} alt={img.description} onClick={() => { setImageIndex(index); setIsOpen(true); }} />
        </Card>);

        objects.push(object);
    }

    return (
        <>
            <CardColumns className="mt-3">
                {objects}
            </CardColumns>
            {isOpen && (
                <Lightbox
                    mainSrc={images[imageIndex].src}
                    mainSrcThumbnail={images[imageIndex].thumbnail}
                    nextSrc={images[(imageIndex + 1) % images.length].src}
                    nextSrcThumbnail={images[(imageIndex + 1) % images.length].thumbnail}
                    prevSrc={images[(imageIndex + images.length - 1) % images.length].src}
                    prevSrcThumbnail={images[(imageIndex + images.length - 1) % images.length].thumbnail}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setImageIndex((imageIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setImageIndex((imageIndex + 1) % images.length)
                    }
                    enableZoom={false}
                    imageCaption={images[imageIndex].description}
                />)}
        </>
    );
}

export default PatrikGallery;
