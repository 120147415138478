import Category from './Categories';

const animals = [
  {
    src: '/photos/Animals/Animals-001.jpg',
    thumbnail: '/photos/Animals/Thumbnails/Animals-001.jpg',
    description: 'Lizard',
    categories: [Category.animal]
  },
  {
    src: '/photos/Animals/Animals-002.jpg',
    thumbnail: '/photos/Animals/Thumbnails/Animals-002.jpg',
    description: 'Meerkat',
    categories: [Category.animal]
  },
  {
    src: '/photos/Animals/Animals-003.jpg',
    thumbnail: '/photos/Animals/Thumbnails/Animals-003.jpg',
    description: 'Chihuahua dog 1',
    categories: [Category.animal]
  },
  {
    src: '/photos/Animals/Animals-004.jpg',
    thumbnail: '/photos/Animals/Thumbnails/Animals-004.jpg',
    description: 'Chihuahua dog 2',
    categories: [Category.animal]
  },
  {
    src: '/photos/Animals/Animals-005.jpg',
    thumbnail: '/photos/Animals/Thumbnails/Animals-005.jpg',
    description: 'Peacock',
    categories: [Category.animal]
  },
  {
    src: '/photos/Animals/Animals-006.jpg',
    thumbnail: '/photos/Animals/Thumbnails/Animals-006.jpg',
    description: 'Lonely monkey',
    categories: [Category.animal]
  },
  {
    src: '/photos/Animals/Animals-007.jpg',
    thumbnail: '/photos/Animals/Thumbnails/Animals-007.jpg',
    description: 'A parrot in the water',
    categories: [Category.animal]
  },
  {
    src: '/photos/Animals/Animals-008.jpg',
    thumbnail: '/photos/Animals/Thumbnails/Animals-008.jpg',
    description: 'Meerkats',
    categories: [Category.animal]
  },
  {
    src: '/photos/Animals/Animals-009.jpg',
    thumbnail: '/photos/Animals/Thumbnails/Animals-009.jpg',
    description: 'Alpaca',
    categories: [Category.animal]
  }
];

const concerts = [
  {
    src: '/photos/Concert/Concert-001.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-001.jpg',
    description: 'Tomi Meglič, Siddharta',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-002.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-002.jpg',
    description: 'Siddharta',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-003.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-003.jpg',
    description: 'Tretja Špila',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-004.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-004.jpg',
    description: 'Kingston',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-005.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-005.jpg',
    description: 'Crvena jabuka',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-006.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-006.jpg',
    description: 'Željko Bebek',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-007.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-007.jpg',
    description: 'S.A.R.S.',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-008.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-008.jpg',
    description: 'Dan D',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-009.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-009.jpg',
    description: 'Guitar',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-010.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-010.jpg',
    description: 'Nina Pušlar',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-011.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-011.jpg',
    description: 'Magnifico',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-012.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-012.jpg',
    description: 'Parni Valjak',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-013.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-013.jpg',
    description: 'Alja Krušič',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-014.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-014.jpg',
    description: 'Elvis Jackson',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-015.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-015.jpg',
    description: 'Mi2',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-016.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-016.jpg',
    description: 'Jan Plestenjak',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-017.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-017.jpg',
    description: "Jan Plestenjak's band",
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-018.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-018.jpg',
    description: 'Siddharta',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-019.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-019.jpg',
    description: 'Siddharta',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-020.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-020.jpg',
    description: 'Tadej Toš',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-021.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-021.jpg',
    description: 'Jože Potrebuješ, Čuki',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-022.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-022.jpg',
    description: 'Čuki',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-023.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-023.jpg',
    description: 'Vlado Kreslin',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-024.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-024.jpg',
    description: 'Ian Sound',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-025.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-025.jpg',
    description: 'Orchestra',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-026.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-026.jpg',
    description: 'Cello',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-027.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-027.jpg',
    description: 'Orchestra',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-028.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-028.jpg',
    description: 'Rock concert',
    categories: [Category.concerts]
  },
  {
    src: '/photos/Concert/Concert-029.jpg',
    thumbnail: '/photos/Concert/Thumbnails/Concert-029.jpg',
    description: 'Tabu',
    categories: [Category.concerts]
  }
];

const events = [
  {
    src: '/photos/Events/Event-001.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-001.jpg',
    description: 'Alice in Wonderland',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-002.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-002.jpg',
    description: 'Children & wedding',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-003.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-003.jpg',
    description: 'Wedding',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-004.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-004.jpg',
    description: 'Saxophone',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-005.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-005.jpg',
    description: "Midsummer night's dream",
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-006.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-006.jpg',
    description: 'Prom dance',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-007.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-007.jpg',
    description: 'Prom dance',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-008.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-008.jpg',
    description: 'Night hike',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-009.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-009.jpg',
    description: 'Maja Keuc - Amaya',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-010.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-010.jpg',
    description: 'Improvisational theatre',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-011.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-011.jpg',
    description: 'Disco',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-012.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-012.jpg',
    description: 'Alice in Wonderland',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-013.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-013.jpg',
    description: 'Alice in Wonderland',
    categories: [Category.event]
  },
  {
    src: '/photos/Events/Event-014.jpg',
    thumbnail: '/photos/Events/Thumbnails/Event-014.jpg',
    description: 'DJ',
    categories: [Category.event]
  }
];

const nature = [
  {
    src: '/photos/Nature/Nature-002.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-002.jpg',
    description: 'Urban',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-003.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-003.jpg',
    description: 'Fram Waterfall',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-004.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-004.jpg',
    description: 'Magic tree',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-005.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-005.jpg',
    description: 'Snowy nature',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-006.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-006.jpg',
    description: 'Bled',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-007.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-007.jpg',
    description: 'Pohorje lakes',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-008.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-008.jpg',
    description: 'Pohorje',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-009.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-009.jpg',
    description: 'Bled',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-010.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-010.jpg',
    description: 'Maribor at night',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-011.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-011.jpg',
    description: 'Piramida',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-012.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-012.jpg',
    description: 'Carinthian nature',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-013.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-013.jpg',
    description: 'Shooting star',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-014.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-014.jpg',
    description: 'Lovrenška jezera',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-015.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-015.jpg',
    description: 'Maribor at day',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-016.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-016.jpg',
    description: 'Beautiful turquoise lake',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-017.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-017.jpg',
    description: 'Playing with shadows',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-018.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-018.jpg',
    description: 'Mostar',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-019.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-019.jpg',
    description: 'Kravica waterfalls',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-020.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-020.jpg',
    description: 'Sarajevo',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-021.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-021.jpg',
    description: 'Foggy Maribor',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-022.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-022.jpg',
    description: 'Lavender field',
    categories: [Category.nature]
  },
  {
    src: '/photos/Nature/Nature-023.jpg',
    thumbnail: '/photos/Nature/Thumbnails/Nature-023.jpg',
    description: 'In between three countries',
    categories: [Category.nature]
  }
];

const others = [
  {
    src: '/photos/Others/Others-001.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-001.jpg',
    description: 'Torch',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-002.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-002.jpg',
    description: 'Florence',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-003.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-003.jpg',
    description: 'Il Duomo di Firenze',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-004.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-004.jpg',
    description: 'Gingerbread village',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-005.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-005.jpg',
    description: 'Christmas spirit',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-006.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-006.jpg',
    description: 'Franz Kafka - The Metamorphosis',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-007.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-007.jpg',
    description: 'Kościuszko Mound in Kraków',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-008.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-008.jpg',
    description: 'Budapest',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-009.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-009.jpg',
    description: 'Ljubljana castle',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-010.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-010.jpg',
    description: 'Wine cellar Maribor',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-011.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-011.jpg',
    description: 'Wine tasting',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-012.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-012.jpg',
    description: 'Sarajevo',
    categories: [Category.other]
  },
  {
    src: '/photos/Others/Others-013.jpg',
    thumbnail: '/photos/Others/Thumbnails/Others-013.jpg',
    description: 'Contemporary art',
    categories: [Category.other]
  }
];

const sports = [
  {
    src: '/photos/Sports/Sports-001.jpg',
    thumbnail: '/photos/Sports/Thumbnails/Sports-001.jpg',
    description: 'Headball',
    categories: [Category.sport]
  },
  {
    src: '/photos/Sports/Sports-002.jpg',
    thumbnail: '/photos/Sports/Thumbnails/Sports-002.jpg',
    description: 'Futsal',
    categories: [Category.sport]
  },
  {
    src: '/photos/Sports/Sports-003.jpg',
    thumbnail: '/photos/Sports/Thumbnails/Sports-003.jpg',
    description: 'GPE formula',
    categories: [Category.sport]
  },
  {
    src: '/photos/Sports/Sports-004.jpg',
    thumbnail: '/photos/Sports/Thumbnails/Sports-004.jpg',
    description: 'Tina Maze',
    categories: [Category.sport]
  },
  {
    src: '/photos/Sports/Sports-005.jpg',
    thumbnail: '/photos/Sports/Thumbnails/Sports-005.jpg',
    description: 'Ultimate frisbee',
    categories: [Category.sport]
  },
  {
    src: '/photos/Sports/Sports-006.jpg',
    thumbnail: '/photos/Sports/Thumbnails/Sports-006.jpg',
    description: 'Urban gladiator',
    categories: [Category.sport]
  },
  {
    src: '/photos/Sports/Sports-007.jpg',
    thumbnail: '/photos/Sports/Thumbnails/Sports-007.jpg',
    description: 'Swimming in lake',
    categories: [Category.sport]
  },
  {
    src: '/photos/Sports/Sports-008.jpg',
    thumbnail: '/photos/Sports/Thumbnails/Sports-008.jpg',
    description: 'Cheerleading',
    categories: [Category.sport]
  },
  {
    src: '/photos/Sports/Sports-010.jpg',
    thumbnail: '/photos/Sports/Thumbnails/Sports-010.jpg',
    description: 'European cheerleading championship',
    categories: [Category.sport]
  }
];

const portraits = [
  {
    src: '/photos/Portrait/Portrait-001.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-001.jpg',
    description: 'M.V.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-005.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-005.jpg',
    description: 'Š.D.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-006.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-006.jpg',
    description: 'M.K.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-009.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-009.jpg',
    description: 'A.J.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-010.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-010.jpg',
    description: 'A.J.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-012.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-012.jpg',
    description: 'A.J.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-015.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-015.jpg',
    description: 'N.L.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-018.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-018.jpg',
    description: 'S.G.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-020.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-020.jpg',
    description: 'N.L.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-022.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-022.jpg',
    description: 'Tigercats cheer dance team',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-023.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-023.jpg',
    description: 'T.S.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-024.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-024.jpg',
    description: 'T.S.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-026.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-026.jpg',
    description: 'A.C.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-029.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-029.jpg',
    description: 'H.Z.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-030.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-030.jpg',
    description: 'S.G.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-032.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-032.jpg',
    description: 'S.G.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-035.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-035.jpg',
    description: 'S.G.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-036.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-036.jpg',
    description: 'S.G.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-039.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-039.jpg',
    description: 'Z.H.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-044.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-044.jpg',
    description: 'Z.H.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-047.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-047.jpg',
    description: 'Z.H.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-048.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-048.jpg',
    description: 'Z.H.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-051.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-051.jpg',
    description: 'P & P',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-052.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-052.jpg',
    description: 'My P.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-053.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-053.jpg',
    description: 'My P.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-054.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-054.jpg',
    description: 'My P.',
    categories: [Category.portrait]
  },
  {
    src: '/photos/Portrait/Portrait-055.jpg',
    thumbnail: '/photos/Portrait/Thumbnails/Portrait-055.jpg',
    description: 'My P.',
    categories: [Category.portrait]
  }

];


const images = [
  ...animals,
  ...concerts,
  ...events,
  ...nature,
  ...others,
  ...sports,
  ...portraits
];

const img = shuffle(images);
function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}


export default img;